<template>
  <v-card>
    <v-window v-model="window" touchless>
      <v-window-item :value="0">
        <v-card-text class="pt-3">
          <div class="headline">
            {{item.level}}
            <v-chip
              :color="`${item.status === 'Active' ? 'success' : 'error'} white--text`"
              class="caption"
            >{{item.status || 'NA'}}</v-chip>
          </div>
          <div>Activated: {{item.dtCreated | unixToLocalDt}}</div>
          <div>Amount Paid: {{item.amount | usDollars}}</div>
          <div v-if="item.deleted">Deactivated: {{item.dtModified | unixToLocalDt}}</div>
          <div v-if="!item.deleted">Expires: {{item.expiry | readableDate}}</div>
          <v-btn
            color="color3"
            text x-small
            v-if="item.orderId"
            :to="{name: 'order-details', params: {orderId: item.orderId}}"
          >
            view order
          </v-btn>
          <v-btn
            color="color3"
            text x-small
            v-if="canDeactivate"
            @click.stop="edit"
          >
            edit
          </v-btn>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="1">
        <v-card-text class="py-0">
          <date-time-picker
            :datetime.sync="expires"
            label="Manual Expiration"
          ></date-time-picker>
          <v-checkbox label="Deactivate this membership" v-model="deleted" color="color3"></v-checkbox>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="success"
            small fab
            :disabled="!dirty"
            :loading="loading"
            @click="patch"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
          <v-btn
            color="error"
            @click.stop="window--"
            small fab
            :disabled="loading"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-actions>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
const DateTimePicker = () => import('@/components/Utils/DateTimePicker')

export default {
  props: ['item', 'program'],
  data () {
    return {
      window: 0,
      expires: null,
      deleted: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    org () {
      return this.program.organization.username
    },
    canDeactivate () {
      return this.user && (this.user.isPageAdmin(this.org) || this.user.vbl)
    },
    dirty () {
      return this.expires !== this.item.dtExpires || this.deleted !== this.item.deleted
    },
    dto () {
      return this.dirty && {
        id: this.item.id,
        expires: this.expires,
        deleted: this.deleted
      }
    }
  },
  methods: {
    edit () {
      this.expires = this.item.dtExpires
      this.deleted = this.item.deleted
      this.window = 1
    },
    patch () {
      this.loading = true
      console.log(this.dto)
      this.$VBL.organization.members.pathcLevel(this.org, this.dto)
        .then(r => {
          this.item.dtExpires = this.expires
          this.item.deleted = this.deleted
          this.item.dtModified = moment()
          if (this.item.status !== r.data) {
            this.item.status = r.data
            this.$emit('status-change')
          }
          this.window = 0
        })
        .catch(e => { console.log(e) })
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    DateTimePicker
  }
}
</script>
