<template>
  <div>
    <v-card>
      <v-card-title class="headline text-center justify-center">
        Membership History
      </v-card-title>
      <v-divider></v-divider>
      <v-expand-transition>
        <v-card-text v-if="history">
          <v-timeline dense style="margin-left: -40px;">
            <v-timeline-item
              v-for="(l, i) in history.levelHistory"
              :key="i"
              right
            >
              <span slot="opposite">{{l.dtCreated | unixToLocalDt}}</span>
              <membership-history-item
                :item="l"
                :program="program"
                @status-change="() => {$emit('status-change')}"
              ></membership-history-item>
            </v-timeline-item>
            <v-timeline-item
              right
            >
              <span slot="opposite">{{history.createdOn | unixToLocalDt}}</span>
              <v-card>
                <v-card-text>
                  <div class="xsmall">{{history.createdOn | unixToLocalDt}}</div>
                  <div class="headline">Member Created</div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <v-row dense>
            <v-col cols="12">

            </v-col>
          </v-row>
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import MembershipHistoryItem from './MembershipHistoryItem'
export default {
  props: ['history', 'program'],
  components: {
    MembershipHistoryItem
  }
}
</script>
