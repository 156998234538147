<template>
  <div>
    <loading-bar :value="iLoading"></loading-bar>
    <v-tabs
      v-model="currentTab"
      background-color="color2"
      slider-color="color3"
      touchless
    >
      <v-tab href="#status" class="color2Text--text">Status</v-tab>
      <v-tab href="#levels" class="color2Text--text">Levels</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item value="status">
        <membership-history
          :history="history"
          :program="program"
          @status-change="() => {$emit('status-change')}"
        ></membership-history>
      </v-tab-item>
      <v-tab-item value="levels">
        <level-select
          ref="levelSelector"
          :program="program"
          :loading="loading"
          :player="member"
          :active="active"
          @add-level="onAddLevel"
        ></level-select>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LevelSelect from '@/components/Membership/LevelSelect.vue'
import MembershipHistory from '@/components/Membership/Member/MembershipHistory.vue'
import moment from 'moment'
import { firstBy } from 'thenby'

export default {
  props: ['program', 'member', 'loading', 'active'],
  data () {
    return {
      currentTab: null,
      history: null,
      iLoading: false,
      deactivate: null,
      dialog: false
    }
  },
  methods: {
    onAddLevel (v) {
      this.$emit('add-level', v)
    },
    getHistory () {
      this.iLoading = true
      this.$VBL.organization.members.getHistory(this.member.id, this.program.organization.username)
        .then(r => {
          this.history = r.data
          this.history.levelHistory.forEach(h => {
            h.unix = moment(h.dtCreated).unix()
          })
          this.history.levelHistory.sort(firstBy('unix', -1))
        })
        .catch(e => console.log(e))
        .finally(() => { this.iLoading = false })
    }
  },
  watch: {
    active: function (val) {
      val && this.getHistory()
    }
  },
  mounted () {
    if (this.member.status !== 'Active') this.currentTab = 'levels'
    this.active && this.getHistory()
  },
  components: {
    LevelSelect,
    MembershipHistory
  }
}
</script>
